<template>
  <div>
    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-card>
        <b-row>
          <b-col xl="12" md="12" sm="12" cols="12">
            <h1 class="text-primary my-1">Import Excel Customer</h1>
            <b-row>
              <b-col cols="6" xl="6" md="12" sm="12">
                <b-form-group class="mb-40">
                  <div>
                    <b-form-file accept=".xls, .xlsx" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="importExcelFile" ref="file-input" />
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="6" xl="6" md="12" sm="12">
                <b-form-group class="mb-40">
                  <b-input-group>
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>
                    <b-input-group-append>
                      <b-button @click="filter = ''" variant="primary">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-table
              striped
              hover
              ref="refUserListTable"
              class="position-relative"
              :style="totalRows < 3 ? 'height: 500px' : ''"
              :items="listCustomers"
              @sort-changed="sortingChanged"
              :no-local-sorting="true"
              selectable
              select-mode="single"
              responsive
              :small="true"
              head-variant="dark"
              :fields="customerHeaders"
              show-empty
              empty-text="No matching records found"
              @row-selected="onRowSelected"
              :per-page="perPage"
            >
              <!-- Column: id -->
              <template #cell(id)="data">
                <strong class="text-primary">#{{ Number(data.item.id ? data.item.id : 0) }}</strong>
              </template>
              <!-- Column: firstName -->
              <template #cell(firstName)="data">
                {{ data.item.firstName ? data.item.firstName : 'N/A' }}
              </template>
              <!-- Column: lastName -->
              <template #cell(lastName)="data"> {{ data.item.lastName ? data.item.lastName : 'N/A' }}</template>
              <!-- Column: email -->
              <template #cell(email)="data">
                {{ data.item.email ? data.item.email : 'N/A' }}
              </template>
              <!-- Column: sellerType -->
              <template #cell(sellerType)="data">
                <b-badge v-if="data.item.sellerType == '1'" pill variant="light-primary" class="badge-glow">Individual</b-badge>
                <b-badge v-if="data.item.sellerType == '2'" pill variant="light-warning" class="badge-glow">Business</b-badge>
              </template>
              <!-- Column: phoneNumber -->
              <template #cell(phoneNumber)="data">
                {{ data.item.phoneNumber ? data.value : 'N/A' }}
              </template>
              <!-- Column: driverLicenseNumber -->
              <template #cell(driverLicenseNumber)="data">{{ data.item.driverLicenseNumber ? data.item.driverLicenseNumber : 'N/A' }} </template>
              <!-- Column: city -->
              <template #cell(city)="data">
                {{ data.item.city ? data.item.city : 'N/A' }}
              </template>
              <!-- Column: status -->
              <template #cell(status)="data">
                <b-badge v-if="data.item.status == 'A'" pill variant="success" class="badge-glow">Active</b-badge>
                <b-badge v-if="data.item.status == 'B'" pill variant="secondary" class="badge-glow">Passive</b-badge>
              </template>

              <!-- Column: dealer -->
              <template #cell(dealer)="data">
                {{ data.item.dealer ? data.item.dealer : 'N/A' }}
              </template>
              <!-- Column: dateOfBirth -->
              <template #cell(dateOfBirth)="data">
                {{ data.item.dateOfBirth ? data.item.dateOfBirth : 'N/A' }}
              </template>
              <!-- Column: unitNumber -->
              <template #cell(unitNumber)="data">
                {{ data.item.unitNumber ? data.item.unitNumber : 'N/A' }}
              </template>
              <!-- Column: postalCode -->
              <template #cell(postalCode)="data">
                {{ data.item.postalCode ? data.item.postalCode : 'N/A' }}
              </template>
              <!-- Column: provence -->
              <template #cell(provence)="data">{{ data.item.provence ? data.item.provence : 'N/A' }} </template>
              <!-- Column: country -->
              <template #cell(country)="data">
                {{ data.item.country ? data.item.country : 'N/A' }}
              </template>
              <!-- Column: otherIdNumber -->
              <template #cell(otherIdNumber)="data">
                {{ data.item.otherIdNumber ? data.item.otherIdNumber : 'N/A' }}
              </template>
              <!-- Column: gst -->
              <template #cell(gst)="data"> {{ data.item.gst ? data.item.gst : 'N/A' }}</template>
              <!-- Column: pst -->
              <template #cell(pst)="data">
                {{ data.item.pst ? data.item.pst : 'N/A' }}
              </template>
              <!-- Column: updated_at -->
              <template #cell(updated_at)="data">
                {{ data.item.updated_at ? dateFormat(data.item.updated_at) : 'N/A' }}
              </template>
              <!-- Column: created_at -->
              <template #cell(created_at)="data">
                {{ data.item.created_at ? dateFormat(data.item.created_at) : 'N/A' }}
              </template>

              <!-- <template #cell(sellerType)="data">
            <b-badge v-if="data.item.sellerType == '1'" pill variant="light-primary" class="badge-glow">Individual</b-badge>
            <b-badge v-if="data.item.sellerType == '2'" pill variant="light-warning" class="badge-glow">Business</b-badge>
          </template> -->

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                  </template>
                  <b-dropdown-item
                    :to="{
                      name: 'customer-edit',
                      params: { id: data.item.id },
                    }"
                    v-if="$Can('customer_show')"
                  >
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Details</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    :to="{
                      name: 'customer-edit',
                      params: { id: data.item.id },
                    }"
                    v-show="where == 'settings-importExcelCustomer'"
                    v-if="$Can('customer_edit')"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>

                  <!-- <b-dropdown-item @click="sendToArchive(data.item.id, data)" v-show="$Can('customer_send_archive')" v-if="data.item.archive == 'no'">
                <feather-icon icon="SendIcon" />
                <span class="align-middle ml-50">Send To Archive </span>
              </b-dropdown-item> -->
                </b-dropdown>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                </b-col>

                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { BFormFile } from 'bootstrap-vue';
import axiosIns from '@/libs/axios';
import store from '@/store';
import router from '@/router';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import moment from 'moment';
import _ from 'lodash';

export default {
  components: {
    BFormFile,
  },
  mounted() {
    this.getItems();
  },
  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    return { selected: ['Admin', 'User'] };
  },
  data() {
    return {
      excelJson: {},
      excelJsonSheet2: {},
      edit: null,
      newitem: '',
      perPage: localStorage.getItem('customerShow') ?? 10,
      filter: null,
      filterOn: [],
      loading: false,
      items: [],
      baseURL: store.state.app.baseURL,
      where: router.currentRoute.name,
      currentPage: 1,
      pageOptions: [5, 10, 15, 20],
      totalRows: 0,
      fetchInvoices: [],
      listCustomers: [],
      customerHeaders: [],
      colWidthFactor: null,
      tempStatusOptions: [
        { label: 'Draft', value: 'A' },
        { label: 'Pending Manager', value: 'B' },
        { label: 'Approved', value: 'C' },
        { label: 'Not Approved', value: 'D' },
        { label: 'Sent To Client', value: 'E' },
        { label: 'Win', value: 'F' },
        { label: 'Loss', value: 'P' },
        { label: 'Canceled Sale', value: 'J' },
        { label: 'Win-Closed', value: 'H' },
      ],
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  methods: {
    dateFormat(val) {
      return moment(String(val)).format('DD-MM-YYYY');
    },

    onRowSelected(item) {
      router.push({ name: 'customer-edit', params: { id: item[0].id } });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    sortingChanged(val) {
      this.sortBy = val.sortBy;
      this.sortDesc = val.sortDesc;
    },
    getItems(val) {
      this.loading = true;
      store
        .dispatch('settings/getAllCustomers')
        .then((res) => {
          let filterExcelImportTrue = _.filter(res.data.data, function(o) {
            return o.excelImport;
          });

          this.totalRows = filterExcelImportTrue.length;
          this.listCustomers = filterExcelImportTrue;

          this.createTable(res.data.headers, res.data.customer_columns);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createTable(userheaders, headerColumns) {
      if (userheaders) {
        userheaders.forEach((items) => {
          this.colWidthFactor = this.colWidthFactor + headerColumns.find((x) => x.value == items).percentage;
        });

        userheaders.forEach((items) => {
          var columns = headerColumns.find((x) => x.value == items);
          var width = 'width: ' + String(columns.percentage * (95 / this.colWidthFactor)) + '%';
          this.customerHeaders.push({
            key: items,
            label: columns.title,
            sortable: true,
            class: 'text-center',
            thStyle: width,
          });
        });
        this.customerHeaders.push({
          key: 'actions',
          class: 'text-center',
          thStyle: 'width: 5%',
        });
        this.loading = false;
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
    importExcelFile(e) {
      var files = e.target.files,
        f = files[0];
      var reader = new FileReader();
      const self = this;
      reader.onload = function(e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });
        let sheetName = workbook.SheetNames[0];

        let worksheet = workbook.Sheets[sheetName];

        this.excelJson = XLSX.utils.sheet_to_json(worksheet, { defval: null });

        let controlExcelColumn = 0;
        let matchRegex = /(firstName|lastName|email|phoneNumber|sellerType)/g;

        if (this.excelJson.length > 0 && this.excelJson != {}) {
          for (let x in this.excelJson[0]) {
            if (x.match(matchRegex)) {
              controlExcelColumn += 1;
            }
          }
          if (controlExcelColumn === 5) {
            this.excelJson.forEach((date) => {
              if (!date['dateOfBirth'].toString().includes('-')) {
                date['dateOfBirth'] = self.excelDateToJSDate(date['dateOfBirth']);
              }
            });

            axiosIns
              .post('import-excel-customer', this.excelJson)
              .then((res) => {
                self.$refs['file-input'].reset();
                self.getItems();
                self.$swal({
                  title: `${res.data.success_added_data == 0 ? 'warning' : 'success'}`,
                  //   text: `${res.data.success_added_data} data successfully added. But ${res.data.error_rows} rows didn't add. Please check your rows and load those rows with a new excel `,
                  html: `
                  <span style="${res.data.success_added_data == 0 ? 'display:none' : ''}">${res.data.success_added_data} data successfully added.</span>
                  <br>
                  <span style="${res.data.error_rows.length > 0 ? '' : 'display:none'}">
                  <span style="${res.data.success_added_data == 0 ? 'display:none' : ''}">But</span> <b style="color:red">${res.data.error_rows}</b> row(s) didn't add. Please check your rows because, these customer(s) already exist and load those rows with a new excel
                  </span>`,
                  icon: `${res.data.success_added_data == 0 ? 'warning' : 'success'}`,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                });
              })
              .catch((error) => {
                self.$swal({
                  title: 'Error!',
                  text: 'Oops, Something went wrong while loading excel, please try again',
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                });

                console.log(error);
              });
          } else {
            self.$swal({
              title: 'Error!',
              text: 'Oops, This excel table headers not correct. Make sure the headers are correct',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
          }
        }
      };
      reader.readAsArrayBuffer(f);
    },
    excelDateToJSDate(serial) {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);
      return date_info.getDate() + '-' + (date_info.getMonth() + 1) + '-' + date_info.getFullYear();
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem('customerShow', val);

        this.getItems();
      },
    },

    accountType: {
      handler: function(val, before) {
        this.getItems();
      },
    },

    currentPage: {
      handler: function(val, before) {
        this.getItems();
      },
    },

    filter: _.debounce(function(newVal) {
      this.getItems();
    }, 500),

    sortDesc: {
      handler: function(val, before) {
        if (this.sortBy != '') {
          this.getItems();
        }
      },
    },
  },
};
</script>
